import React from "react";
import ideas from "./meeting.jpg";
import flyer from "./JobHuntFlyer.png";
import juniorflyer from "./Junior Football trainingpng.png";
import fair from "./fair1.jpg";
import orch from "./orch.jpg";
import football from "./futbol1.jpg";
import football2 from "./futbol2.jpg";
import group from "./group1.jpg";
import swing from "./swing1.jpg";
import community from "./communityfix.jpg";
import bikeposter from "./cyclingflyer.jpg";
import table from "./tablefutbol.jpg";
import halfflyer from "./octhalftermposter.jpg";
import gloves from "./blue-boxing-gloves-g49b45d927_1920.jpg";
import "./activities.css";

export default function ActivitiesP(){
    return (
    <div className="grid-container">
        <div className="grid-item1">
            <h2>Activities & Projects</h2>
        </div><div className="grid-item2">
        <h3>Southside Harmonics Steel Orchestras</h3>
        <p><strong>Wednesdays & Fridays. 7pm-10pm</strong><br/><br/><br/> Southside Harmonics is one of Europe's premier Steel Orchestras providing weekly music and arts sessions to young people in the community, allowing them access to performances, tours, concerts and competitions.  Continuing to be in the forefront of steelband music with its exuberant and vibrant performances, Southside has been noted for its excellence in carnival arts and work in the community. Southside continues to strive for the best and maintain excellence.  If you are looking for a Steelband? - then SOUTHSIDE HARMONICS are here to help. Please feel free to browse around our site and if you require any assistance please feel free to contact us<br/><br/> <strong>Tel 07958 303 274 <br/><br/> Email s.harmonics@btinternet.com</strong></p>
         <img src={orch}/>
         </div><div className="grid-item3">
        <h3>Lifelong Family Links</h3>
        <p>Special Needs after school club. Monday, Tuesday, Wednesday & Saturdays</p>
        <p>Lifelong Family Links works in partnership with children, young people and adults with a disability, their carers, relatives and community-based service providers to deliver flexible services within the community designed to improve the lives of disabled people. We were established on 1982. Our key aim is to ensure young people with Special Educational Needs and Disabilities (SEND) to live, learn and
            work within their local community, achieve sustained progression, resulting in better life outcomes disabled people achieve their full potential, to enjoy a rich and full individual and family life, and one in which they engage with, and contribute, to the local community. This includes accessing voluntary and paid employment.</p>
            <a href="https://www.lfl.org.uk"><div className="buttonLFL">Visit</div></a>
        </div><div className="grid-item4">
            <h3>Homes for Lambeth: local hub</h3>
            <p><strong>Engagement Team  Every Thursday 11am - 4pm.</strong></p>
            <p>For any queries about the rebuilding programme on the estate</p>
            <img src={ideas} />
        </div><div className="grid-item5">
            <h3>Job Hub</h3>
            <p><strong>Thursdays 11am - 4pm</strong></p>
            <p>CV workshop, Training and skills, Interview Preparation, Help with Applications, Employment opportinites</p>
            <a href="./resources/images/JobHuntFlyer.png"><img  src={flyer} /></a>
            <img src={fair} />
            </div><div className="grid-item6">
            <h3>Stockwell Strikers U13's</h3>
        <p><strong>Football training every Monday 4.30pm - 6.30pm</strong></p>
        <p>Under 13's Football training.  We will be adding different age groups in the near future.</p>
        <img src={football} />
        <img src={football2} />
        </div><div className="grid-item7">
        <h3>Willington Adventure Playground</h3>
    <p><strong>Term time Thursdays & Fridays 4pm-7pm, half terms 11pm - 6pm</strong></p>
    <p>Arts & Crafts, indoor games, to sports, cooking, gardening, adventure structures.</p>
    <img src={group}/>
    <img src={swing}/>
    <img src={table}/>
    <img src={halfflyer}/>
    
    </div>
    <div className="grid-item8">
        <h3>Juniors Football kick about. 2-6 years</h3>
        <p><strong>Thursday's term time 4.30pm - 6pm</strong></p>
        <p>Football training sessions for ages 2-6.   </p>
        <a href="./Junior Football trainingpng.png"><img  src={juniorflyer} /></a>

    </div>
    <div className="grid-item9">
        <h3>Over 11's Boxing</h3>
        <p><strong>Coming soon on Thursdays!</strong></p>
        <img src={gloves}/>
        
    </div>

    <div className="grid-itemcycle">
        <h3>Cycle training for ages 6-8 and Cycle maintenance training for ages 11-15</h3>
        <p><strong>June 14th and June 17th 3.30pm - 5.30pm</strong></p>
        <img src={bikeposter}/>

    </div>
    <div className="grid-itemcom">
        <h3>Community payback scheme</h3>
        <p><strong>Fridays 8am - 3pm</strong></p>
        <p>community payback scheme will support the centre and community.</p>
        <img src={community}/>
    </div>
    </div>
    )
    
    }