import React from "react";
import cups from "./cups.JPG";
import indoorFac from "./indoorfac.JPG";
import balloons from "./lflpaper.jpg";
import childFriendly from "./child-friendly-lambeth-logo.jpg";
import games from "./games.jpg";
import crafts from "./crafts.jpg";
import group from "./group1.jpg";
import football from "./futbol1.jpg";
import yp from "./yp1.jpg";
import bikeout from "./bike1.jpg";
import tablef from "./tablefutbol.jpg";
import "./aboutUs.css";
export default function Aboutus(){
    return (
        <div className="grid-container1">
            <div>
        <h2 className="grid-item11">Mission Statement</h2>
        <p className="grid-item22">We want to create a vision for a safer, stronger and more cohesive community, with opportunities for self-development and growth being accessible by all members of our community, to work to enhance the quality of life for young people and the residents of North Clapham, West Brixton and South Stockwell areas. We will deliver programmes of to break down barriers between different social groups, to challenge preconceptions and contribute to community safety. To Support the development of children and young people at Willington. To act as a hub within the local community to encourage young people to engage in positive activities, offering formal an informal educational opportunity. To help children grow both as individuals and as members of society.</p>
            </div>
        
                    <div className="grid-item33">
                <img src={cups} />
                
                <img src={games} />
                </div>
                <div className="grid-item55">
                <img src={group}/>
                <img src={yp}/>
                </div>
                <div className="grid-item44">
                <img src={balloons} />
                <img src={football}/>
                    </div>
                    <div className="grid-item66">
                    <img src={bikeout}/>
                    <img src={tablef}/>
                
                    </div>


        </div>
        
        
        
        
        

    )
    
    }