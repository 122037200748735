import React from "react"
import playground from "./playground1.JPG";
import basketball from "./basketball.JPG";
import indoorhall from "./indoorHall.JPG";
import loungehall from "./lounge1.JPG";
import kitchen from "./kitchen1.JPG";
import parking from "./parking.JPG";
import "./facilities.css";
export default function Facilities(){
    return (
        <div className="containerA">

          <div className="grid-itemA">
            <h1 className="grid-itemA">Facilities </h1>
            </div>
        
        
            <div className="grid-itemB">
              <img src={playground} />
            
            <div className="desc">Adventure Playground
            
            </div>
            
            </div>
        
        
        
        
            <div className="grid-itemC">
              <img src={basketball} />
            
            <div className="desc">Outdoor sports area</div>
          </div>
        
        
        
          <div className="grid-itemD">
              <img src={indoorhall} />
          
            <div className="desc">Indoor sports</div>
          </div>
        
        
        
          <div className="grid-itemE">
              <img src={loungehall} />
            
            <div className="desc">Lounge</div>
          </div>
        
        
          <div className="grid-itemF">
                <img src={kitchen} />
              
              <div className="desc">Kitchen</div>
            </div>
          
            <div className="grid-itemG">
                <img src={parking} />
                <div className="desc">Parking</div>
                </div>
              
              
           
          
          
        
        
        
       
        
        </div>
    )
    
    }