import React from "react";
import "./home.css";
import twitter from "./Twitter social icons - circle - blue.png";
import insta from "./instagram-logo.png";
import { useState } from "react";
import CookieConsent from "./CookieConsent";

export default function Home(){

   
   //make function when button clicked to hide cookie container
    const [cookie, setCookie] = useState(true);
    const cookieButton = () => {
        setCookie(false);
    };
        
return (
    <div className="titlebackground">
        <h1 className="home-title">Willington Road Youth & Community Hub</h1>
        
        <div className="soc-logo">
        <a href="https://www.instagram.com/willingtoncommunityhub/"><img src={insta} className="img"/></a>
            <a href="https://twitter.com/WillingtonHub"><img src={twitter} className="img"/></a>
            
            </div>
            
            
            <CookieConsent/>
            
    </div>
)

}