import { BrowserRouter, Route, Routes, Link, NavLink,} from 'react-router-dom';
import './App.css';
import Home from './Components/pages/Home';
import Aboutus from './Components/pages/Aboutus';
import ActivitiesP from './Components/pages/Activities';
import Facilities from './Components/pages/Facilities';
import Contactus from './Components/pages/Contact';
import twitter from "./Components/pages/Twitter social icons - circle - blue.png";
import insta from "./Components/pages/instagram-logo.png";

function App() {
  return (
    <BrowserRouter>
        <header className='grid-container'>
          <nav className='loca'>
            

            <Link to="/" className='navi'>Home</Link>
            <NavLink to="about" className='navi'>About</NavLink>
            <NavLink to="activities" className='navi'>Activities</NavLink>
            <NavLink to="facilities" className='navi'>Facilities</NavLink>
            <NavLink to="contact" className='navi'>Contact</NavLink>
            
          </nav>
        </header>
        <main>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="about" element={<Aboutus/>}/>
        <Route path="activities" element={<ActivitiesP/>} />
        <Route path="facilities" element={<Facilities/>} />
        <Route path="contact" element={<Contactus/>} />
      </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
