import './contact.css';
import friendlogo from "./child-friendly-lambeth-logo.jpg";

export default function Contactus(){
    return (
        <div className='contact'>
            
        <footer>
            <h2 className="flextitle"> Contact Page</h2>
            <div className='contact-info'>
            <p>Lambeth Council</p>
            <p>Address: Willington Road Youth & community Hub, 55 Willington Road SW9 9NB</p>
            <p>Tel: 0753 422 4019</p>
            <p>Email: rhyacinthe@lambeth.gov.uk</p>
            <img src={friendlogo}/>
            
            
            </div>

        </footer>
        
        </div>
    )
    
    }