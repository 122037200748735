import React, { useState, useEffect } from "react";
import "./cookie-consent.css";
const CookieConsent = () => {
    const [acceptedCookies, setAcceptedCookies] = useState(false);
  
    useEffect(() => {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');
      if (cookiesAccepted) {
        setAcceptedCookies(true);
      }
    }, []);
  
    const handleAcceptCookies = () => {
      localStorage.setItem('cookiesAccepted', 'true');
      setAcceptedCookies(true);
    };
  
    const handleDeclineCookies = () => {
      localStorage.removeItem('cookiesAccepted');
      setAcceptedCookies(false);
    };
  
    return (
      <div className="cookie-container">
        {acceptedCookies ? (
          <p>Cookies have been accepted.</p>
        ) : (
          <>
            <p>This website uses cookies to ensure you get the best experience on our website.</p>
            <button className="cookie-btn" onClick={handleAcceptCookies}>Accept</button>
            <button className="cookie-btn" onClick={handleDeclineCookies}>Decline</button>
          </>
        )}
      </div>
    );
  };
  
  export default CookieConsent;